import { type LoaderFunctionArgs } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import Footer from '#app/components/footer'
import { getFooterProps } from '#app/components/footer.server'
import { HeaderGuest } from '#app/components/header-guest'
import { getHeaderGuestProps } from '#app/components/header-guest.server'
import { handleAffiliateTrackingSearchParam } from '#app/utils/affiliate-tracking.server'
import { getParamsLocale } from '#app/utils/request.server'
import { requireGuest } from '#app/utils/session.server'

export async function loader({ params, request }: LoaderFunctionArgs) {
	const locale = getParamsLocale(params)

	await requireGuest({ locale, request })
	await handleAffiliateTrackingSearchParam(request)

	return {
		footerProps: getFooterProps({ locale, role: 'guest' }),
		headerGuestProps: getHeaderGuestProps(locale),
	}
}

export default function GuestLayout() {
	const data = useLoaderData<typeof loader>()

	return (
		<div className="flex min-h-screen flex-col">
			<HeaderGuest {...data.headerGuestProps} />
			<Outlet />
			<Footer {...data.footerProps} />
		</div>
	)
}
